import React from 'react';
import FormElementComponent from './FormElementComponent';

const FormPreview = ({ elements, updateElement, moveElement, deleteElement }) => {
    return (
        <div className="bg-white p-4 rounded shadow">
            <h2 className="text-2xl font-bold mb-4">Form Preview</h2>
            {elements.map((element, index) => (
                <FormElementComponent
                    key={element.id}
                    element={element}
                    index={index}
                    updateElement={updateElement}
                    moveElement={moveElement}
                    deleteElement={deleteElement}
                />
            ))}
        </div>
    );
};

export default FormPreview;
