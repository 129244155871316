import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';

export default function DateTimepicker({ label, onChangeval, dateSegment }) {
  const currentDate = dayjs();

  const customTheme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#000000',
            maxWidth: '90%',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
              borderWidth: '1px',
            },
          },
          notchedOutline: {
            borderWidth: '1px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#000000',
            '&.Mui-focused': {
              color: '#000000',
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#058270',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#04695b',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: '#000000',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#058270',
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{
            paddingTop: dateSegment ? 0 : '8px',
            overflow: 'hidden',
          }}
          components={dateSegment ? ['DatePicker'] : ['DateTimePicker']}
        >
          {dateSegment ? (
            <DatePicker
              maxDate={currentDate}
              fullWidth
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  padding: '0 8px',
                },
                '& .MuiIconButton-root': {
                  margin: '0 2px',
                  padding: '4px',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                '& .MuiTypography-root': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              onChange={(newDate) => {
                if (newDate.isAfter(currentDate)) {
                  const dateString = newDate.format('YYYY-MM-DD');
                  onChangeval(dateString);
                } else {
                  console.log('Selected date must be after the current date.');
                }
              }}
            />
          ) : (
            <DateTimePicker
              label={label}
              defaultValue={currentDate}
              minDate={currentDate}
              onChange={(newDate) => {
                if (newDate.isAfter(currentDate)) {
                  const dateTimeString = newDate.format('YYYY-MM-DD HH:mm:ss');
                  onChangeval(dateTimeString);
                } else {
                  console.log(
                    'Selected date and time must be after the current date and time.'
                  );
                }
              }}
              fullWidth
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  color: '#000000',
                },
              }}
            />
          )}
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
