import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import { Button, AppBar, Box, Toolbar } from '@mui/material';
import customCodePlugin from 'grapesjs-custom-code';
import grapesjsNavbar from 'grapesjs-navbar';
import CustomBlocks from '../builder/CustomBuilder';
import blockBasic from 'grapesjs-blocks-basic';
import FormPlugin from 'grapesjs-plugin-forms';
import 'grapesjs/dist/css/grapes.min.css';
import './editor.css';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const Editor = ({ onClose }) => {
    const editorRef = useRef(null);

    useEffect(() => {
        if (!editorRef.current) {
            editorRef.current = grapesjs.init({
                container: '#gjs',
                height: '100vh',
                fromElement: true,
                plugins: [customCodePlugin, grapesjsNavbar, blockBasic, FormPlugin],
                pluginsOpts: {
                    'grapesjs-custom-code': {
                        blockCustomCode: {
                            label: 'Custom Code',
                            category: 'Extra',
                        },
                    },
                },
            });
            CustomBlocks(editorRef.current);
        }
    }, []);

    const handleSave = () => {
        const html = editorRef.current.getHtml();
        const css = editorRef.current.getCss();
        console.log(html, css);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            bgcolor: '#fff'
        }}>
            <Box sx={{
                flex: 1,
                width: '100%',
                overflow: 'auto'
            }}>
                <div id="gjs" className='pl-2' />
            </Box>
            <AppBar
                position="static"
                color="default"
                elevation={1}
                sx={{
                    top: 'auto',
                    bottom: 0,
                    bgcolor: 'background.paper'
                }}
            >
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 1
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<CloseIcon />}
                            onClick={onClose}
                            sx={{ width: 120 }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                            sx={{ width: 120 }}
                        >
                            Save
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Editor;
