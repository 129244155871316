import AddBox from '@mui/icons-material/AddBox';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import toast from 'react-hot-toast';
import { CreateCampaign } from 'Api/Api';
import { SideDrawer } from 'components/SideDrawer';
import { getCurrentDateTimeLocal } from 'utils/commonFunctions';
import CreateTemplateModal from '../Templates/CreateTemplateModal';
import { CampaignStyle } from './campaignStyle';
import { Loading } from 'components/Loading';
import DateTimePicker from 'components/DateTimePicker';
import { InnerTextField } from 'components/InputFields';
import { CampaignScheuduler } from 'constants/appConstant';
import { CustomSelect } from 'components/CustomSelect';
import CreateSegmentModal from '../Segments/CreateSegmentModal';
import CustomTooltip from 'components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { set } from 'react-hook-form';
import Push_NotifyIcon from '../../../assets/Icons/SideBar/Push Notif.png';
import SocialIcon from '../../../assets/Icons/SideBar/social proof.png';
import CreateRealCampaign from '../RealtimeNotifications/CreateRealCampaign';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

const CreateCampaignModal = ({
  open,
  onClose,
  refresh,
  templates = [],
  segments = [],
  FetchTemplate,
  FetchSegment,
  openRealTime,
  refreshRealTime,
}) => {
  const [campaignDetails, setCampaignDetails] = useState({
    campaignName: '',
    templateID: null,
    segmentID: null,
    frequency: 'now',
    frequencyDateTime: '',
    status: 'Active',
    Type: 'Push_Notifications',
    subscribersInteractionNeeded: false,
    selectedCountry: null,
  });
  const [errors, setErrors] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedSegment, setSelectedSegment] = useState();
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [segmentModalOpen, setSegmentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [firstStep, setFirstStep] = useState(true);

  const closeModals = () => {
    setTemplateModalOpen(false);
    setSegmentModalOpen(false);
  };

  const handleNext = () => {
    setFirstStep(false);
  };

  const handleToggle = () => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      status: prevState.status === 'Active' ? 'Inactive' : 'Active',
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCampaignDetails((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prev) => ({
      ...prev,
      [name]: value || null,
    }));
    setErrors((prev) => ({ ...prev, [name]: !value.trim() }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setCampaignDetails((prev) => ({
      ...prev,
      frequency: value,
      frequencyDateTime:
        value !== 'on_specific_date'
          ? getCurrentDateTimeLocal()
          : prev.frequencyDateTime,
    }));
    setErrors((prev) => ({ ...prev, frequency: !value.trim() }));
  };

  const handleSubmit = async () => {
    const newErrors = {
      campaignName: !campaignDetails.campaignName.trim(),
      templateID: campaignDetails.templateID === null,
      segmentID: campaignDetails.segmentID === null,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      setLoading(true);
      try {
        let formData = new FormData();
        Object.entries(campaignDetails).forEach(([key, value]) => {
          if (key === 'templateID' || key === 'segmentID') {
            formData.append(key, value ? parseInt(value) : null);
          } else {
            formData.append(key, value);
          }
        });

        const response = await CreateCampaign(formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
          setCampaignDetails({
            campaignName: '',
            templateID: null,
            segmentID: null,
            frequency: 'now',
            frequencyDateTime: '',
            status: 'Active',
            Type: 'Push_Notifications',
          });

          setFirstStep(true);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (campaignDetails.frequency === 'now') {
      setCampaignDetails((prev) => ({
        ...prev,
        frequencyDateTime: getCurrentDateTimeLocal(),
      }));
    }
  }, [campaignDetails.frequency]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countryNames = data.map((country) => ({
          label: country.name.common,
        }));
        const completeCountries = [
          { label: 'Subscriber Local Timezone' },
          ...countryNames,
        ];
        setCountries(completeCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    getCountries();
  }, []);

  const handleClose = () => {
    onClose();
    setCampaignDetails({
      campaignName: '',
      templateID: null,
      segmentID: null,
      frequency: 'now',
      frequencyDateTime: '',
      status: 'Active',
      Type: 'Push_Notifications',
    });
    setFirstStep(true);
  };

  // start Realtime

  const [state, setState] = useState({
    campaignName: campaignDetails.campaignName || '',
    messageText: '',
    status: false,
    allowClose: false,
    loading: false,
    isPreview: false,
    tabValue: '1',
    searchTerm: '',
    selectedSegment: null,
    showDropdown: false,
    url: '',
    formData: {
      title: '',
      description: '',
      mobilePosition: '',
      allowButtonText: '',
      allowButtonTextColor: '',
      allowButtonBackgroundColor: '',
      laterButtonText: '',
    },
    image: { logo: null },
    segments: [
      { id: 1, name: 'Segment 1' },
      { id: 2, name: 'Segment 2' },
      { id: 3, name: 'Segment 3' },
    ],
  });

  const segmentOptions = [
    { id: 1, name: 'Segment 1' },
    { id: 2, name: 'Segment 2' },
    { id: 3, name: 'Segment 3' },
  ];

  const handleRealTimeChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFormDataChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [key]: value,
      },
    }));
  };

  const handleRealTimeSubmit = async (event) => {
    event.preventDefault();
    const { campaignName, messageText, status } = state;
    const campaignData = { campaignName, messageText, status };
    handleRealTimeChange('loading', true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      refreshRealTime(campaignData);
      onClose();
      toast.success('Static campaign created successfully');
    } catch (error) {
      console.error('Error creating static campaign:', error);
      toast.error('Error creating static campaign');
    } finally {
      handleRealTimeChange('loading', false);
    }
  };

  const handleTabChange = (event, newValue) => {
    handleRealTimeChange('tabValue', newValue);
  };

  const handleInputFocus = () => {
    handleRealTimeChange('showDropdown', true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      handleRealTimeChange('showDropdown', false);
    }, 100);
  };

  const debounceSearch = useCallback((e) => {
    handleRealTimeChange('searchTerm', e.target.value);
  }, []);

  const filteredResults = useMemo(() => {
    return segmentOptions.filter((segment) =>
      segment.name.toLowerCase().includes(state.searchTerm.toLowerCase())
    );
  }, [state.searchTerm]);

  const handleSegmentSelect = (segment) => {
    handleRealTimeChange('selectedSegment', segment);
    handleRealTimeChange('searchTerm', segment.name);
    handleRealTimeChange('showDropdown', false);
  };

  const handleClear = () => {
    handleRealTimeChange('searchTerm', '');
    handleRealTimeChange('selectedSegment', null);
    handleRealTimeChange('showDropdown', false);
  };

  useEffect(() => {
    if (open) {
      setState((prevState) => ({
        ...prevState,
        campaignName: '',
        messageText: '',
        status: false,
      }));
    }
  }, [open]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={handleClose}
        title={'Create Campaign'}
        handleSubmit={
          firstStep
            ? handleNext
            : campaignDetails.Type === 'Push_Notifications'
              ? handleSubmit
              : handleRealTimeSubmit
        }
        firstStep={firstStep}
      // EyeIcon={true}
      >
        {firstStep ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box>
                  <InnerTextField
                    required
                    name="campaignName"
                    label="Campaign Name"
                    onChange={handleChange}
                    error={errors.campaignName}
                    value={campaignDetails?.campaignName || ''}
                    helperText={errors.campaignName && 'Field required'}
                  />
                </Box>
              </Grid>
            </Grid>

            <Typography sx={{ color: 'black', marginTop: '10px' }}>
              Type
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                name="Type"
                value={campaignDetails.Type}
                onChange={handleChange}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    pt: 1,
                    pb: 2,
                    pl: 2,
                    pr: 1,
                    borderColor: '#036355',
                    my: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value="Push_Notifications"
                      control={<Radio />}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 1,
                          }}
                        >
                          <img
                            src={Push_NotifyIcon}
                            alt="push_NotifyIcon"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{ fontWeight: 'bold', color: 'black' }}
                          >
                            Push Notifications
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ pl: 4 }}
                  >
                    A push notification is a real-time, clickable message sent
                    directly to subscribers' devices, providing instant updates
                    or promotional alerts.
                  </Typography>
                </Paper>

                <Paper
                  variant="outlined"
                  sx={{
                    pt: 1,
                    pb: 2,
                    pl: 2,
                    pr: 1,
                    borderColor: '#036355',
                    my: 1.5,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value="Social_proof"
                      control={<Radio />}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 1,
                          }}
                        >
                          <img
                            src={SocialIcon}
                            alt="SocialIcon"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{ fontWeight: 'bold', color: 'black' }}
                          >
                            Social Proof
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ pl: 4 }}
                  >
                    It enhances trust and sales by leveraging social proof and
                    FOMO notifications. Displaying real-time customer actions
                    like purchases, sign-ups, or reviews, it creates urgency and
                    boosts conversion rates.
                  </Typography>
                </Paper>
              </RadioGroup>
            </FormControl>
          </>
        ) : campaignDetails.Type == 'Push_Notifications' ? (
          <>
            <Box>
              <InnerTextField
                required
                name="campaignName"
                label="Campaign Name"
                onChange={handleChange}
                error={errors.campaignName}
                value={campaignDetails?.campaignName || ''}
                helperText={errors.campaignName && 'Field required'}
              />
            </Box>
            <Box sx={CampaignStyle.selectInputFlex} my={3}>
              <Autocomplete
                disablePortal
                options={templates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.templateName}
                value={selectedTemplate}
                onChange={(e, newValue) => {
                  setSelectedTemplate(newValue);
                  setCampaignDetails((prev) => ({
                    ...prev,
                    templateID: newValue ? parseInt(newValue.id) : null,
                  }));
                  setErrors((prev) => ({ ...prev, templateID: !newValue }));
                }}
                sx={CampaignStyle.autoSelectStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Template"
                    error={errors.templateID}
                    helperText={errors.templateID && 'Field required'}
                    InputLabelProps={{ style: { color: 'black' } }}
                    InputProps={{
                      ...params.InputProps,
                      style: { color: 'black' },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} style={{ color: 'black' }}>
                    {option.templateName}
                  </li>
                )}
              />
              <IconButton onClick={() => setTemplateModalOpen(true)}>
                <ControlPointIcon
                  sx={{ '& path': { fill: '#058270' }, fontSize: 35 }}
                />
              </IconButton>
              <CreateTemplateModal
                refresh={FetchTemplate}
                open={templateModalOpen}
                onClose={closeModals}
                welcome={false}
              />
            </Box>

            <Box sx={CampaignStyle.selectInputFlex} my={3}>
              <Autocomplete
                disablePortal
                options={segments}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.segmentName}
                value={selectedSegment}
                onChange={(e, newValue) => {
                  setSelectedSegment(newValue);
                  setCampaignDetails((prev) => ({
                    ...prev,
                    segmentID: newValue ? parseInt(newValue.id) : null,
                  }));
                  setErrors((prev) => ({ ...prev, segmentID: !newValue }));
                }}
                sx={CampaignStyle.autoSelectStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Segment"
                    error={errors.segmentID}
                    helperText={errors.segmentID && 'Field required'}
                    InputLabelProps={{ style: { color: 'black' } }}
                    InputProps={{
                      ...params.InputProps,
                      style: { color: 'black' },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} style={{ color: 'black' }}>
                    {option.segmentName}
                  </li>
                )}
              />
              <IconButton onClick={() => setSegmentModalOpen(true)}>
                <AddBox
                  sx={{ color: '#058270', height: '50px', width: '50px' }}
                />
              </IconButton>
              <CreateSegmentModal
                refresh={FetchSegment}
                open={segmentModalOpen}
                onClose={closeModals}
              />
            </Box>

            <Box mb={2}>
              <Typography variant="black_4">Status</Typography>
              <Switch
                name="status"
                size="large"
                checked={campaignDetails?.status === 'Active'}
                onChange={handleToggle}
              />
            </Box>

            <Box width="100%" display={'flex'} alignItems={'end'} gap={2}>
              <FormControl sx={{ width: '44%' }}>
                <CustomSelect
                  label="Schedule"
                  margin="normal"
                  fullWidth
                  error={errors.frequency}
                  options={CampaignScheuduler}
                  value={campaignDetails?.frequency}
                  onChange={handleFrequencyChange}
                  helperText={errors.frequency ? 'Field required' : ''}
                />
              </FormControl>

              {/* Need to change this component with Date time picker of MUI */}
              {campaignDetails.frequency !== 'now' && (
                <DateTimePicker
                  label={'Date and time'}
                  onChangeval={(e) => {
                    setCampaignDetails({
                      ...campaignDetails,
                      frequencyDateTime: e,
                    });
                  }}
                />
              )}
            </Box>

            {campaignDetails.frequency !== 'now' && (
              <Box>
                <Box display="flex" alignItems="center" mt={3}>
                  <Typography
                    variant="black_h4"
                    sx={{ fontSize: 18, mr: 1, mb: 1 }}
                  >
                    Subscriber Time Zone
                  </Typography>
                  <CustomTooltip
                    title="This option triggers the workflow according to the subscriber's time zone."
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <IconButton size="small" sx={{ padding: 0 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </CustomTooltip>
                </Box>

                {/* Autocomplete Section */}
                <Box mt={1}>
                  <FormControl fullWidth sx={{ maxWidth: '230px' }}>
                    <Autocomplete
                      disablePortal
                      autoHighlight
                      options={countries}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => option.label}
                      onChange={(e, newValue) => {
                        setCampaignDetails((prev) => ({
                          ...prev,
                          selectedCountry: newValue ? newValue.label : null,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          selectedCountry: !newValue,
                        }));
                      }}
                      sx={CampaignStyle.autoSelectStyle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          error={errors.selectedCountry}
                          helperText={
                            errors.selectedCountry && 'Field required'
                          }
                          InputLabelProps={{ style: { color: 'black' } }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'new-password',
                            style: { color: 'black' },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={`${option.label}-1`}
                          style={{ color: 'black' }}
                        >
                          {option.label}
                        </li>
                      )}
                    />
                  </FormControl>
                </Box>

                {/* Subscribers Interaction Checkbox Section */}
                <Box ml={-1} mt={3}>
                  <FormGroup>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={campaignDetails?.subscribersInteractionNeeded}
                        onChange={handleCheckboxChange}
                        name="subscribersInteractionNeeded"
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '1.1rem',
                          color: 'black',
                        }}
                      >
                        Subscribers Interaction Needed
                      </Typography>

                      <CustomTooltip
                        title="This option requires interaction from subscribers to proceed."
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                      >
                        <IconButton size="small" sx={{ padding: 0, ml: 1 }}>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </CustomTooltip>
                    </Box>
                  </FormGroup>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <CreateRealCampaign
            campaignName={state.campaignName}
            setCampaignName={(value) =>
              handleRealTimeChange('campaignName', value)
            }
            triggerFor={state.triggerFor}
            setTriggerFor={(value) => handleRealTimeChange('triggerFor', value)}
            searchTerm={state.searchTerm}
            debounceSearch={debounceSearch}
            handleInputFocus={handleInputFocus}
            handleClear={handleClear}
            ControlPointIcon={ControlPointIcon}
            showDropdown={state.showDropdown}
            filteredResults={filteredResults}
            handleSegmentSelect={handleSegmentSelect}
            allowClose={state.allowClose}
            setAllowClose={(value) => handleRealTimeChange('allowClose', value)}
            status={state.status}
            setStatus={(value) => handleRealTimeChange('status', value)}
            setSearchTerm={(value) => handleRealTimeChange('searchTerm', value)}
            messageText={state.messageText}
            setMessageText={(value) =>
              handleRealTimeChange('messageText', value)
            }
          />
        )}
      </SideDrawer>
    </>
  );
};

export default CreateCampaignModal;
