import React, { useState, useEffect } from 'react';
import { Box, Slide, Stack, Button, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { SurveyTableColumns } from 'constants/appConstant';
import NoRecord from 'components/NoRecord';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import FormBuilder from './FormBuilder';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Survey = () => {
  const [surveys, setSurveys] = useState([]);
  const [responses, setResponses] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [searchTerms, setSearchTerms] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => { //TODO: fetch surveys
    const fetchSurveys = async () => {
    };
    setInitialLoading(false);
    // fetchSurveys();
  }, []);

  const handleCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleDeleteModel = (surveyId) => {
    setDeleteModalOpen(true);
    setDeleteId(surveyId);
  };

  const handleDelete = () => {
    setSurveys(surveys.filter((survey) => survey.id !== deleteId));
    setDeleteModalOpen(false);
  };

  const handleEditModel = () => {
    // setShowEditor(true);
  };


  return createModalOpen ? (
    <FormBuilder
      onClose={() => setCreateModalOpen(false)}
      onSave={(newSurvey) => {
        setSurveys([...surveys, newSurvey]);
        setCreateModalOpen(false);
      }}
    />
  ) : (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Surveys
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={handleCreateModal}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={surveys}
            rowData={SurveyTableColumns}
            handleDeleteModel={handleDeleteModel}
            initialLoading={initialLoading}
            sortingIndex={[
              'status',
              'name',
              'surveyId',
              'createdTime',
              'modifiedTime'
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Survey"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>
        <DeleteModal
          open={deleteModalOpen}
          close={() => setDeleteModalOpen(false)}
          placeholder="Survey"
          deleteFunction={handleDelete}
        />
      </Box>
    </MainDashboard>
  );
};

export default Survey;
