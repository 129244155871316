import { useState } from 'react';
import { Typography, Stack, Box, Tooltip, IconButton } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { CustomFormInput } from 'components/Style';
import { RoundedButton } from 'components/Buttons';
import { RegisterUser } from 'Api/Api';
import { useNavigate } from 'react-router-dom';
import { loginScrollbar } from 'components/Style';
import toast from 'react-hot-toast';
import {
  MobileNumberValidation,
  PasswordValidation,
  isEmailValid,
} from 'utils/commonFunctions';
import CheckBox from 'components/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import { useForm } from 'react-hook-form';
import theme from 'styles/app.theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomTooltip from 'components/Tooltip';

export const SignUpContainer = ({ showLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const [userDetails, setUserDetails] = useState({
    tandc: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  function onChangeReCaptcha(e) {
    setUserDetails((x) => ({ ...x, ['reCaptcha']: e }));
  }

  const handleSignup = async (data) => {
    if (!userDetails.reCaptcha) {
      toast.error("Please verify that you're not a robot");
      return;
    }
    if (!userDetails.tandc) {
      toast.error('Please accept the Terms and Conditions');
      return;
    }
    try {
      const response = await toast.promise(RegisterUser(data), {
        loading: 'Loading...',
        success: (response) => response?.data?.message,
        error: (error) => error?.response?.data?.message,
      });
      if (response?.data?.status === true) {
        navigate(0);
      }
    } catch (error) {}
  };
  
  return (
    <Box
      sx={{
        ...loginScrollbar,
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        width: '100%',
        height: '100%',
        maxWidth: 420,
        boxSizing: 'border-box',
        overflowY: 'scroll',
      }}
    >
      <Typography variant="h5" textAlign="center">
        Sign Up
      </Typography>
      <Typography variant="subtitle2" my={1} textAlign="center">
        Start engaging your customers on board
      </Typography>
      <Typography
        mt={1}
        color="#FF0000"
        sx={{ textAlign: 'left', wordSpacing: '2px' }}
      >
        *All the fields are mandatory
      </Typography>
      <form>
        <Box>
          <Stack direction={'column'} spacing={2} mt={2}>
            <Box>
              <Typography mb={1} variant="subtitle2">
                Enter company / Website name
              </Typography>
              <CustomFormInput
                placeholder={'Lets Notify'}
                {...register('companyName', {
                  required: 'Field required*',
                })}
              />
              {errors.companyName && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.companyName?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Enter Full Name
              </Typography>
              <CustomFormInput
                placeholder={'Enter full name'}
                {...register('userName', { required: 'Field required*' })}
              />
              {errors.userName && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.userName?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Role
              </Typography>
              <CustomFormInput
                placeholder={'System Engineer'}
                {...register('role', {
                  required: 'Field required*',
                })}
              />
              {errors.role && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.role?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Enter your Email
              </Typography>
              <CustomFormInput
                placeholder="example@gmail.com"
                {...register('email', {
                  required: 'Field required*',
                  validate: {
                    validEmail: (value) =>
                      isEmailValid(value) || 'Email format is not valid',
                  },
                })}
              />
              {errors.email && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.email?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Mobile Number
              </Typography>
              <CustomFormInput
                placeholder={'+911203145684'}
                {...register('mobileNumber', {
                  required: {
                    value: true,
                    message: 'Field required*',
                  },
                  validate: {
                    validMobile: (value) =>
                      MobileNumberValidation(value) ||
                      'Mobile No. is not valid',
                  },
                })}
              />
              {errors.mobileNumber && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.mobileNumber?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography
                mb={1}
                variant="subtitle2"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                Password
                <CustomTooltip
                  title={
                    <Box sx={{ padding: 1 }}>
                      <Typography variant="body2" fontWeight="bold" mb={1}>
                        Your password must contain at least:
                      </Typography>
                      <ul
                        style={{
                          paddingLeft: '1.5em',
                          listStyleType: 'disc',
                          color: 'white',
                        }}
                      >
                        <li style={{ fontWeight: 'normal' }}>
                          One lowercase letter.
                        </li>
                        <li style={{ fontWeight: 'normal' }}>
                          One Uppercase letter.
                        </li>
                        <li style={{ fontWeight: 'normal' }}>One number.</li>
                        <li style={{ fontWeight: 'normal' }}>
                          One special character.
                        </li>
                      </ul>
                    </Box>
                  }
                  arrow
                  placement="right"
                >
                  <IconButton
                    size="small"
                    sx={{ marginLeft: 0.5, backgroundColor: 'transparent' }}
                  >
                    <InfoIcon
                      fontSize="inherit"
                      sx={{ backgroundColor: 'white' }}
                    />
                  </IconButton>
                </CustomTooltip>
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <CustomFormInput
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Field required*',
                    },
                    validate: {
                      validPassword: (value) =>
                        PasswordValidation(value) ||
                        'Password is not valid Password',
                    },
                  })}
                />
                <IconButton
                  disableRipple
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'text.secondary',
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}{' '}
                </IconButton>
              </Box>
              {errors.password && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.password?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Confirm Password
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <CustomFormInput
                  placeholder="Re-enter your password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  {...register('confirmPassword', {
                    required: {
                      value: true,
                      message: 'Field required*',
                    },
                    validate: {
                      matchesPreviousPassword: (value) =>
                        value === getValues('password') ||
                        'Passwords must match',
                    },
                  })}
                />
                <IconButton
                  disableRipple
                  onClick={() => setConfirmShowPassword((prev) => !prev)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'text.secondary',
                  }}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}{' '}
                </IconButton>
              </Box>
              {errors.confirmPassword && (
                <Typography sx={theme.typography.errorSandyBrown}>
                  {errors?.confirmPassword?.message}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={3}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChangeReCaptcha}
          />
        </Box>

        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={2}
        >
          <CheckBox
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              setUserDetails((x) => ({ ...x, tandc: e.target.checked }));
            }}
          />
          <Typography variant="subtitle2" textAlign={'center'}>
            I Accept{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                window.open('/termsandcondition', '_blank');
              }}
            >
              Terms & Conditions
            </span>
          </Typography>
        </Box>

        <Box textAlign={'center'} mt={2}>
          <RoundedButton
            title={'Sign Up'}
            onClick={handleSubmit(handleSignup)}
          />
        </Box>

        <Typography variant="subtitle2" textAlign={'center'} mt={2}>
          Already have an Account?{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={showLogin}
          >
            Login
          </span>
        </Typography>
      </form>
    </Box>
  );
};
