import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Switch,
  Typography,
  FormControl,
  RadioGroup,
  Paper,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { InnerTextField } from 'components/InputFields';
import { CustomSelect } from 'components/CustomSelect';
import { CreateSegment, TotalSubscribers } from 'Api/Api';
import toast from 'react-hot-toast';
import { SideDrawer } from 'components/SideDrawer';
import { SegmentConditionArray, SegmentFieldsArray,CalenderArray,TimePeriodArray,ActionArray, PersonHasArray,ConditionsArray} from 'constants/appConstant';
import { Loading } from 'components/Loading';
import { trashIcon } from 'constants/appImages';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { styled } from "@mui/material/styles";
import DateTimepicker from 'components/DateTimePicker';



const CreateSegmentModal = ({ open, onClose, refresh }) => {

  const initialField = {
    conditionName: '',
    field: '',
    condition: 'In_the_last',
    value: '',
    Person_has: 'Active_on_Site',
    action: 'At_least_once',
    Days: '',
    toggleValue: "and",
    equalValue: '',
    beforeValue: '',
  };

  const initialPeopleField = {
    conditionName: initialField.conditionName || '',
    field: '',
    condition: '',
    value: '',
    toggleValue: "and",
  };

  const initialWhatField = {
    conditionName: initialField.conditionName || '',
    field: '',
    condition: 'In_the_last',
    value: '',
    Person_has: 'Active_on_Site',
    action: 'At_least_once',
    Days: '',
    toggleValue: "and",
    equalValue: '',
    beforeValue: '',
  };

  const [segmentData, setSegmentData] = useState({
    segmentName: '',
    Type: 'static',
    status: 'Active',
    subscriberCount: 0,
    condition: [{ ...initialField }]

  });
  const [loading, setLoading] = useState(false);

  const removeCondition = (index) => {
    const newSegmentData = { ...segmentData };
    const conditions = [...newSegmentData.condition];

    // If there's only one condition, reset segment data to the default structure
    if (conditions.length === 1) {
      setSegmentData({
        segmentName: segmentData.segmentName || '',
        Type: segmentData.Type || '',
        status: segmentData.status || '',
        subscriberCount: 0,
        condition: [{ ...initialField }],
      });
      return;
    }

    // If an AND condition is deleted and the next condition is OR, change the next OR to AND
    if (conditions[index]?.toggleValue === "and" && conditions[index + 1]?.toggleValue === "or") {
      conditions[index + 1].toggleValue = "and";
    }

    // If an OR condition is deleted and it was part of a group of ORs
    if (conditions[index]?.toggleValue === "or") {
      // Check if the previous condition is AND and the next is OR, change the next OR to AND
      if (conditions[index - 1]?.toggleValue === "and" && conditions[index + 1]?.toggleValue === "or") {
        conditions[index + 1].toggleValue = "and";
      }
    }

    // Remove the specified condition
    conditions.splice(index, 1);

    // Update segment data with the modified conditions
    newSegmentData.condition = conditions;
    setSegmentData(newSegmentData);
  };



  const handleTypeChange = (e, index) => {
    const { name, value } = e.target;
    const newSegmentData = { ...segmentData };
    newSegmentData.condition[index][name] = value;
    setSegmentData(newSegmentData);
  };

  const handleToggle = (e) => {
    const name = e.target.name;
    if (name === 'status') {
      const newStatus =
        segmentData?.status === 'Active' ? 'Inactive' : 'Active';
      setSegmentData((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSegmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCount = async () => {
    try {
      let countData = segmentData?.condition;
      const response = await TotalSubscribers(countData);
      if (response?.data?.status === true) {
        const newCount = response?.data?.data;
        setSegmentData((prevState) => ({
          ...prevState,
          subscriberCount: newCount,
        }));
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!segmentData.segmentName.trim()) {
      errors.segmentName = true;
    }
    segmentData.condition.And.forEach((condition, index) => {
      if (!condition.field || !condition.condition || !condition.value) {
        errors[`And_${index}`] = true;
      }
    });
    segmentData.condition.Or.forEach((condition, index) => {
      if (!condition.field || !condition.condition || !condition.value) {
        errors[`Or_${index}`] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      toast.error('All fields are required.');
      return;
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const response = await CreateSegment(segmentData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  // start

  const [firstStep, setFirstStep] = useState(true)

  const handleNext = () => {

    setFirstStep(false)

  }

  const handleToggleChange = (index, event, newAlignment) => {
    if (newAlignment !== null) {
      const updatedSegmentData = { ...segmentData };
      const updatedConditions = [...updatedSegmentData.condition];

      updatedConditions[index].toggleValue = newAlignment;
      updatedSegmentData.condition = updatedConditions;

      setSegmentData(updatedSegmentData);
    }
  };

  const handleToggleChangeStyle = (index, newAlignment) => {
    if (newAlignment !== null) {
      const updatedSegmentData = { ...segmentData };
      const updatedConditions = [...updatedSegmentData.condition];

      updatedConditions[index].toggleValue = newAlignment;
      updatedSegmentData.condition = updatedConditions;

      setSegmentData(updatedSegmentData);
    }
  };

  const handleAddCondition = (index) => {
    const updatedSegmentData = { ...segmentData };
    const updatedConditions = [...updatedSegmentData.condition];

    // Create a new condition based on the initialField template
    const newCondition = {
      ...initialField,
      id: updatedConditions.length + 1,
      toggleValue: "or", // Default value for toggle
    };

    // Find the last OR in the current group starting from the given index
    let lastOrIndex = index;
    for (let i = index + 1; i < updatedConditions.length; i++) {
      if (updatedConditions[i].toggleValue === "or") {
        lastOrIndex = i;
      } else {
        break;
      }
    }

    // Insert the new condition after the last OR in the group
    updatedConditions.splice(lastOrIndex + 1, 0, newCondition);

    // Update segmentData with the modified conditions
    updatedSegmentData.condition = updatedConditions;
    setSegmentData(updatedSegmentData);
  };

  const handleClose = () => {
    onClose();
    setSegmentData({
      segmentName: '',
      Type: 'static',
      status: 'Active',
      subscriberCount: 0,
      condition: [{ ...initialField }]
    });
    setFirstStep(true);
  }


  const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    padding: '6px',
    backgroundColor: '#D0D0D0',
    borderRadius: '4px',
    width: '142px',

  }));

  const StyledButton = styled(Button)(({ theme, isAnd }) => ({
    width: '20px',
    height: '35px',
    fontSize: '16px',
    padding: '0',
    fontWeight: 'normal',
    backgroundColor: isAnd ? '#FFFFFF' : '#D0D0D0',
    color: '#000000',
    borderRadius: '4px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: isAnd ? '#FFFFFF' : '#D0D0D0',
      boxShadow: 'none',
    },
  }));

  const TabContentFirst = ({ tabValue, index }) => {
    switch (tabValue) {
      case 'Equals':
      case 'Does_not_equal':
      case 'Is_at_least':
      case 'Is_greater_than':
      case 'Is_less_than':
      case 'Is_at_most':
        return (
          <Grid item xs={4} >
            <CustomSelect
              label="Action"
              name="action"
              margin="dense"
              fullWidth
              options={ActionArray}
              value={tabValue}
              onChange={(e) => handleTypeChange(e, index)}
            />
          </Grid>
        );

      case 'At_least_once':
      case 'Zero_times':
        return <></>;

      default:
        return <></>;
    }
  };

  const TabContentSecond = ({ tabValue, orIndex }) => {
    // Render UI based on the tabValue
    switch (tabValue) {
      case 'Equals':
      case 'Does_not_equal':
      case 'Is_at_least':
      case 'Is_greater_than':
      case 'Is_less_than':
      case 'Is_at_most':
        return (
          <>
            <Grid item xs={5}>
              <InnerTextField
                fullWidth
                margin="none"
                label="Value"
                name="equalValue"
                value={segmentData.condition[orIndex]?.equalValue}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
            {segmentData.condition[orIndex]?.condition !== 'Over_all_time' &&
              (
                <Grid item xs={4} >
                  <CustomSelect
                    label="Condition"
                    name="condition"
                    margin="dense"
                    fullWidth
                    options={TimePeriodArray}
                    value={segmentData.condition[orIndex]?.condition}
                    onChange={(e) => handleTypeChange(e, orIndex)}
                  />
                </Grid>
              )}
          </>
        );

      case 'At_least_once':
      case 'Zero_times':
        return (
          <>
            <Grid item xs={5} >
              <CustomSelect
                label="Action"
                name="action"
                margin="dense"
                fullWidth
                options={ActionArray}
                value={tabValue}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
            {['Between', 'Between_dates'].includes(segmentData.condition[orIndex]?.condition) && (
              <Grid item xs={4}>
                <CustomSelect
                  label="Condition"
                  name="condition"
                  margin="dense"
                  fullWidth
                  options={TimePeriodArray}
                  value={segmentData.condition[orIndex]?.condition}
                  onChange={(e) => handleTypeChange(e, orIndex)}
                />
              </Grid>
            )}

          </>
        );


      default:
        return <></>;
    }
  };

  const TabContentThird = ({ tabValue, orIndex }) => {
    // Render UI based on the tabValue
    switch (tabValue) {
      case 'In_the_last':
      case 'At_least':
        return (
          <>
            {
              segmentData.condition[orIndex]?.action === 'At_least_once' ||
                segmentData.condition[orIndex]?.action === 'Zero_times' ? (
                <>
                  <Grid item xs={5}>
                    <CustomSelect
                      label="Condition"
                      name="condition"
                      margin="dense"
                      fullWidth
                      options={TimePeriodArray}
                      value={segmentData.condition[orIndex]?.condition}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InnerTextField
                      fullWidth
                      margin="none"
                      label="Value"
                      name="value"
                      value={segmentData.condition[orIndex]?.value}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomSelect
                      label="Days"
                      name="Days"
                      margin="dense"
                      fullWidth
                      options={CalenderArray}
                      value={segmentData.condition[orIndex]?.Days}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={5}>
                    <InnerTextField
                      fullWidth
                      margin="none"
                      label="Value"
                      name="value"
                      value={segmentData.condition[orIndex]?.value}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      label="Days"
                      name="Days"
                      margin="dense"
                      fullWidth
                      options={CalenderArray}
                      value={segmentData.condition[orIndex]?.Days}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>
                </>
              )
            }
          </>

        );

      case 'After':
      case 'Before':
        return (
          <>
            {
              segmentData.condition[orIndex]?.action === 'At_least_once' ||
                segmentData.condition[orIndex]?.action === 'Zero_times' ? (
                <>
                  <Grid item xs={5}>
                    <CustomSelect
                      label="Condition"
                      name="condition"
                      margin="dense"
                      fullWidth
                      options={TimePeriodArray}
                      value={segmentData.condition[orIndex]?.condition}
                      onChange={(e) => handleTypeChange(e, orIndex)}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <DateTimepicker dateSegment={true} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={5}>
                    <DateTimepicker dateSegment={true} />
                  </Grid>
                </>

              )}
          </>

        );

      case 'Between':
        return (
          <>
            <Grid item xs={2.5}>
              <InnerTextField
                fullWidth
                margin="none"
                label="Value"
                name="value"
                value={segmentData.condition[orIndex]?.beforeValue}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
            <Grid item xs={2.5}>
              <InnerTextField
                fullWidth
                margin="none"
                label="Value"
                name="value"
                value={segmentData.condition[orIndex]?.value}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                label="Days"
                name="Days"
                margin="dense"
                fullWidth
                options={CalenderArray}
                value={segmentData.condition[orIndex]?.Days}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
          </>
        );
      case 'Between_dates':
        return (
          <>
            <Grid item xs={5}>
              <DateTimepicker dateSegment={true} />
            </Grid>
            <Grid item xs={5}>
              <DateTimepicker dateSegment={true} />
            </Grid>
          </>
        );

      case 'Over_all_time':
        return (
          <>
            <Grid item xs={5}>
              <CustomSelect
                label="Condition"
                name="condition"
                margin="dense"
                fullWidth
                options={TimePeriodArray}
                value={segmentData.condition[orIndex]?.condition}
                onChange={(e) => handleTypeChange(e, orIndex)}
              />
            </Grid>
          </>
        )
      default:
        return <></>;
    }
  };

  const LineConnector = ({ left, top, height }) => {
    return (
      <Grid item xs={2}>
        <Box sx={{
          position: 'absolute',
          left: left,
          top: top,
          bottom: '0',
          width: '1px',
          height: height ? height : 'auto',
          backgroundColor: '#64707D',
        }} />
        <Box sx={{
          position: 'absolute',
          left: '10%',
          top: '57%',
          width: '9%',
          height: '1px',
          backgroundColor: '#64707D',
        }} />

      </Grid>
    )
  }

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={handleClose}
        title={'New Segment'}
        handleSubmit={firstStep ? handleNext : handleSubmit}
        firstStep={firstStep}
        segment={true}
      >
        {!firstStep && (
          <Box >
            <Box>
              <>
                {segmentData?.condition?.map((condition, index) => {

                  const groupedORs = [];
                  for (let i = index + 1; i < segmentData.condition.length; i++) {
                    if (segmentData.condition[i].toggleValue === "or") {
                      groupedORs.push(i);
                    } else {
                      break;
                    }
                  }

                  if (condition.toggleValue === "or") {
                    return null;
                  }

                  return (
                    <Box key={index}>

                      {segmentData?.condition.length > 1 && index !== 0 && (
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledPaper elevation={0} sx={{ my: 2 }}>
                              <StyledButton
                                isAnd={condition.toggleValue === 'and'}
                                disableRipple
                                onClick={() =>
                                  handleToggleChangeStyle(index, 'and')
                                }
                              >
                                AND
                              </StyledButton>
                              <StyledButton
                                isAnd={condition.toggleValue === 'or'}
                                disableRipple
                                onClick={() =>
                                  handleToggleChangeStyle(index, 'or')
                                }
                              >
                                OR
                              </StyledButton>
                            </StyledPaper>
                          </Box>
                        </Box>
                      )}

                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          padding: "16px",
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          backgroundColor: 'white'
                        }}>
                        {index === 0 && (
                          <Grid container spacing={2}>
                            <Grid item xs={11}>
                              <Box >
                                <InnerTextField
                                  required
                                  name="segmentName"
                                  label="Segment Name"
                                  fullWidth
                                  margin="normal"
                                  value={segmentData.segmentName}
                                  placeholder="Enter segment name"
                                  onChange={handleChange}
                                  sx={{ marginBottom: 0, paddingBottom: 0 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          <Grid container spacing={2} my={1}>
                            <Grid item xs={11}>
                              <CustomSelect
                                label="Conditions"
                                name="conditionName"
                                margin="dense"
                                options={ConditionsArray}
                                value={condition?.conditionName}
                                onChange={(e) => handleTypeChange(e, index)}
                              />
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', }}>
                              <img
                                src={trashIcon}
                                alt="deleteIcon"
                                width={23}
                                height={23}
                                style={{ cursor: 'pointer' }}
                                onClick={() => removeCondition(index)}
                              />
                            </Grid>

                          </Grid>
                          {condition?.conditionName === 'Properties_about_someone' && (
                            <>
                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-22%' />
                                <Grid item xs={5}>
                                  <CustomSelect
                                    label="Field"
                                    name="field"
                                    margin="dense"
                                    fullWidth
                                    options={SegmentFieldsArray}
                                    value={condition?.field}
                                    onChange={(e) => handleTypeChange(e, index)}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-50%' />
                                <Grid item xs={5} >
                                  <CustomSelect
                                    label="Condition"
                                    name="condition"
                                    margin="dense"
                                    fullWidth
                                    options={SegmentConditionArray}
                                    value={condition?.condition}
                                    onChange={(e) => handleTypeChange(e, index)}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-50%' height='108%' />
                                <Grid item xs={5} >
                                  <InnerTextField
                                    fullWidth
                                    margin="none"
                                    label="Value"
                                    name="value"
                                    value={condition?.value}
                                    onChange={(e) => handleTypeChange(e, index)}
                                  />
                                </Grid>
                              </Grid>

                            </>
                          )}

                          {condition?.conditionName === 'What_someone_has_done' && (
                            <>
                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-22%' />
                                <Grid item xs={5}>
                                  <CustomSelect
                                    label="Person has"
                                    name="Person_has"
                                    margin="dense"
                                    fullWidth
                                    options={PersonHasArray}
                                    value={condition?.Person_has}
                                    onChange={(e) => handleTypeChange(e, index)}
                                  />
                                </Grid>

                                <TabContentFirst tabValue={condition?.action} index={index} />


                              </Grid>
                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-50%' />
                                <TabContentSecond tabValue={condition.action} orIndex={index} />
                              </Grid>

                              <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                <LineConnector left='10%' top='-50%' height='108%' />
                                {!TimePeriodArray.some((item) => item.value === condition?.condition) && (
                                  <Grid item xs={5}>
                                    <CustomSelect
                                      label="Condition"
                                      name="condition"
                                      margin="dense"
                                      fullWidth
                                      options={TimePeriodArray}
                                      value={condition?.condition}
                                      onChange={(e) => handleTypeChange(e, index)}
                                    />
                                  </Grid>
                                )}

                                <TabContentThird tabValue={condition?.condition} orIndex={index} />
                              </Grid>
                            </>
                          )}
                        </Grid>

                        {groupedORs.length > 0 && (
                          <Box
                          >
                            {groupedORs.map((orIndex, i) => (
                              <Box key={i}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1 }}>
                                  <StyledPaper elevation={0}>
                                    <StyledButton
                                      isAnd={segmentData.condition[orIndex].toggleValue === 'and'}
                                      disableRipple
                                      onClick={() =>
                                        handleToggleChangeStyle(orIndex, 'and')
                                      }
                                    >
                                      AND
                                    </StyledButton>
                                    <StyledButton
                                      isAnd={segmentData.condition[orIndex].toggleValue === 'or'}
                                      disableRipple
                                      onClick={() =>
                                        handleToggleChangeStyle(orIndex, 'or')
                                      }
                                    >
                                      OR
                                    </StyledButton>
                                  </StyledPaper>
                                </Box>
                                <Grid container>
                                  <Grid container spacing={2} mt={0} mb={1}>
                                    <Grid item xs={11}>
                                      <CustomSelect
                                        label="Conditions"
                                        name="conditionName"
                                        margin="dense"
                                        options={ConditionsArray}
                                        value={segmentData.condition[orIndex]?.conditionName}
                                        onChange={(e) => handleTypeChange(e, orIndex)}
                                      />
                                    </Grid>
                                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', }}>
                                      <img
                                        src={trashIcon}
                                        alt="deleteIcon"
                                        width={23}
                                        height={23}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => removeCondition(orIndex)}
                                      />
                                    </Grid>
                                  </Grid>
                                  {segmentData.condition[orIndex]?.conditionName === 'Properties_about_someone' && (
                                    <>
                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-22%' />
                                        <Grid item xs={5}>
                                          <CustomSelect
                                            label="Field"
                                            name="field"
                                            margin="dense"
                                            fullWidth
                                            options={SegmentFieldsArray}
                                            value={segmentData.condition[orIndex]?.field}
                                            onChange={(e) => handleTypeChange(e, orIndex)}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-50%' />

                                        <Grid item xs={5} >
                                          <CustomSelect
                                            label="Condition"
                                            name="condition"
                                            margin="dense"
                                            fullWidth
                                            options={SegmentConditionArray}
                                            value={segmentData.condition[orIndex]?.condition}
                                            onChange={(e) => handleTypeChange(e, orIndex)}
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-50%' height='108%' />
                                        <Grid item xs={5} >
                                          <InnerTextField
                                            fullWidth
                                            margin="none"
                                            label="Value"
                                            name="value"
                                            value={segmentData.condition[orIndex]?.value}
                                            onChange={(e) => handleTypeChange(e, orIndex)}
                                          />
                                        </Grid>
                                      </Grid>

                                    </>
                                  )}

                                  {segmentData.condition[orIndex]?.conditionName === 'What_someone_has_done' && (
                                    <>
                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-22%' />
                                        <Grid item xs={5}>
                                          <CustomSelect
                                            label="Person has"
                                            name="Person_has"
                                            margin="dense"
                                            fullWidth
                                            options={PersonHasArray}
                                            value={segmentData.condition[orIndex]?.Person_has}
                                            onChange={(e) => handleTypeChange(e, orIndex)}
                                          />
                                        </Grid>

                                        <TabContentFirst tabValue={segmentData.condition[orIndex]?.action} index={orIndex} />


                                      </Grid>
                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-50%' />
                                        <TabContentSecond tabValue={segmentData.condition[orIndex]?.action} orIndex={orIndex} />
                                      </Grid>

                                      <Grid container sx={{ position: 'relative' }} spacing={2} my={1}>
                                        <LineConnector left='10%' top='-50%' height='108%' />
                                        {!TimePeriodArray.some((item) => item.value === segmentData.condition[orIndex]?.condition) && (
                                          <Grid item xs={5}>
                                            <CustomSelect
                                              label="Condition"
                                              name="condition"
                                              margin="dense"
                                              fullWidth
                                              options={TimePeriodArray}
                                              value={segmentData.condition[orIndex]?.condition}
                                              onChange={(e) => handleTypeChange(e, orIndex)}
                                            />
                                          </Grid>
                                        )}

                                        <TabContentThird tabValue={segmentData.condition[orIndex]?.condition} orIndex={orIndex} />
                                      </Grid>
                                    </>
                                  )}

                                </Grid>
                              </Box>
                            ))}
                          </Box>
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1 }}>
                          <Button variant="outlined" onClick={() => handleAddCondition(index)}
                            sx={{
                              border: '1px solid #036355',
                              textTransform: 'none',
                              color: '#036355'
                            }}
                            endIcon={<AddBoxIcon />}
                          >
                            Add Condition
                          </Button>
                        </Box>

                      </Box>
                    </Box>

                  )
                })}

              </>

              <Box my={1}>
                <Typography variant="black_4">Status</Typography>
                <Switch
                  name="status"
                  size="large"
                  checked={segmentData?.status === 'Active'}
                  onChange={handleToggle}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                mb={
                  segmentData?.condition?.And?.length === 0 &&
                    segmentData?.condition?.Or?.length === 0
                    ? 14
                    : 4
                }
              >
                <Typography variant="black_h4">
                  Total numbers of Subscribers : {segmentData?.subscriberCount}
                </Typography>
                <Button variant="outlined" onClick={handleCount}>
                  Apply
                </Button>
              </Box>

            </Box>

          </Box>)
        }

        {firstStep && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={8} >
                <Box>
                  <InnerTextField
                    required
                    name="segmentName"
                    label="Segment Name"
                    fullWidth
                    margin="normal"
                    value={segmentData?.segmentName}
                    placeholder="Enter segment name"
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>

            <FormControl component="fieldset" >
              <RadioGroup name='Type' value={segmentData.Type} onChange={handleChange}>
                <Paper variant="outlined" sx={{ pt: 1, pb: 2, pl: 2, pr: 1, borderColor: '#036355', my: 2 }}>
                  <FormControlLabel
                    value="static"
                    control={<Radio />}
                    label={
                      <Box>
                        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>Static Segment</Typography>
                      </Box>
                    }
                  />
                  <Typography variant="body2" color="textSecondary" sx={{ pl: 4 }}>
                    A static segment is a fixed list of subscribers based on set criteria, unaffected by future changes in subscribers' data.
                  </Typography>
                </Paper>
                <Paper variant="outlined" sx={{ pt: 1, pb: 2, pl: 2, pr: 1, borderColor: '#036355', my: 1.5 }}>
                  <FormControlLabel
                    value="dynamic"
                    control={<Radio />}
                    label={
                      <Box>
                        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>Dynamic Segment</Typography>
                      </Box>
                    }
                  />
                  <Typography variant="body2" color="textSecondary" sx={{ pl: 4 }}>
                    Dynamic segments for existing and new subscribers utilizing real-time data, enabling instant updates based on subscriber behavior, preferences, and interactions for targeted notifications.
                  </Typography>
                </Paper>
              </RadioGroup>
            </FormControl>
          </>
        )}
      </SideDrawer>
    </>
  );
};

export default CreateSegmentModal;
