import React, { useEffect, useState } from 'react';
import {
  Box,
  Switch,
  Typography,
  FormControl,
  Button
} from '@mui/material';
import { DeleteCampaign, FetchAllActionTracker, FetchAllCampaign, FetchAllSegment, FetchAllTemplate, UpdateCampaign } from 'Api/Api';
import { SideDrawer } from 'components/SideDrawer';
import DateTimepicker from 'components/DateTimePicker';
import toast from 'react-hot-toast';
import { formatDateTime, getCurrentDateTimeLocal } from 'utils/commonFunctions';
import { CampaignStyle } from './campaignStyle';
import { InnerTextField } from 'components/InputFields';
import { CustomSelect } from 'components/CustomSelect';
import { CampaignScheuduler } from 'constants/appConstant';
import EditCampaignModal from './EditCampaignModal';
import { editIcon, trashIcon } from 'constants/appImages';
import { DeleteModal } from 'components/Modals';
import { Loading } from 'components/Loading';

const CampaignDetails = ({
  item,
  open,
  onClose,
  itemId,
  refresh,
  refreshCampaign,
  templates = [],
  segments = [],
  fetchSegment,
  fetchTemplate

}) => {
  const [campaignDetails, setCampaignDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await DeleteCampaign(item.id);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        if (refreshCampaign !== undefined) {
          refreshCampaign()
        }
        refresh();
        setLoading(false);

      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const preloadData = async () => {
      try {
        const [fetchedTemplates, fetchedSegments] = await Promise.all([
          templates.length === 0 ? FetchAllTemplate() : Promise.resolve(templates),
          segments.length === 0 ? FetchAllSegment() : Promise.resolve(segments),
        ]);

        const templatesArray = Array.isArray(fetchedTemplates?.data?.data) ? fetchedTemplates.data.data : [];
        const segmentsArray = Array.isArray(fetchedSegments?.data?.data) ? fetchedSegments.data.data : [];

        templates = templatesArray;
        segments = segmentsArray;

        if (Array.isArray(templates)) {
          console.log('Templates:', templates);
          setSelectedTemplate(
            templates.find((template) => template.id === itemId?.template?.id) || null
          );
        } else {
          console.warn('Templates is not an array:', templates);
        }

        if (Array.isArray(segments)) {
          setSelectedSegment(
            segments.find((segment) => segment.id === itemId?.segment?.id) || null
          );
        } else {
          console.warn('Segments is not an array:', segments);
        }

        if (itemId) {
          setCampaignDetails(itemId);
        }
      } catch (error) {
        console.error('Error preloading data:', error);
      }
    };

    preloadData();
  }, [itemId, templates, segments]);

  const handleToggle = (e) => {
    if (e.target.name === 'status') {
      const newStatus =
        campaignDetails?.status === 'Active' ? 'Inactive' : 'Active';
      setCampaignDetails((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '' ? true : false,
    }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setCampaignDetails((prev) => ({
      ...prev,
      frequency: value,
      ...(value !== 'on_specific_date' && { frequencyDateTime: getCurrentDateTimeLocal() }),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      frequency: value.trim() === '' ? true : false,
    }));
  };

  const handleSubmit = async () => {
    const newErrors = {
      campaignName: !campaignDetails.campaignName.trim(),
      templateID: !selectedTemplate?.id,
      segmentID: !selectedSegment?.id,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      try {
        let formData = new FormData();
        const updatedDetails = {
          ...campaignDetails,
          templateID: selectedTemplate?.id,
          segmentID: selectedSegment?.id,
        };

        Object.entries(updatedDetails).forEach(([key, value]) => {
          formData.append(key, value);
        });
        if (campaignDetails?.id) {
          formData.append('id', campaignDetails.id);
        }
        const response = await UpdateCampaign(campaignDetails?.id, formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Update failed.');
      }
    }
  };
  if (loading) {
    return (
      <Loading state={loading} />
    )
  }
  console.log(itemId, "itemid details");
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
          Campaign Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={(handleOpen)} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
            <img src={editIcon} alt="editIcon" width={23} height={23} />
          </Button>
          <Button onClick={() => setDeleteModalOpen(true)} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
            <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
          </Button>
        </Box>
      </Box>

      <EditCampaignModal
        open={openModal}
        onClose={handleClose}
        itemId={itemId}
        refresh={refresh}
        refreshCampaign={refreshCampaign}
        templates={templates}
        segments={segments}
        FetchTemplate={fetchTemplate}
        FetchSegment={fetchSegment}
      />
      <DeleteModal
        open={deleteModalOpen}
        close={handleCloseDelete}
        placeholder="Campaign"
        deleteFunction={() => handleDelete()}
      />
      <InnerTextField
        required
        name="campaignName"
        label="Campaign Name"
        onChange={handleChange}
        error={errors.campaignName}
        value={campaignDetails?.campaignName || ''}
        helperText={errors.campaignName ? 'Field required' : ''}
      />

      <Box>
        <InnerTextField
          label="Template"
          value={itemId?.template?.templateName || ''}
          readOnly
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          label="Segment"
          value={itemId?.segment?.segmentName || ''}
          readOnly
          fullWidth
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">Status</Typography>
        <Switch
          name="status"
          size="large"
          checked={campaignDetails?.status === 'Active'}
          onChange={handleToggle}
        />
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <FormControl sx={{ width: '44%' }}>
          <CustomSelect
            label="Schedule"
            options={CampaignScheuduler}
            value={campaignDetails?.frequency || ''}
            onChange={handleFrequencyChange}
          />
        </FormControl>
        {campaignDetails?.frequency === 'on_specific_date' && (
          <DateTimepicker
            label="Date and time"
            onChangeval={(e) =>
              setCampaignDetails((prev) => ({ ...prev, frequencyDateTime: e }))
            }
          />
        )}
      </Box>

      <Box>
        <Typography variant="subtitle2" my={1}>
          Created time: {formatDateTime(campaignDetails?.createdTime)}
        </Typography>
        <Typography variant="subtitle2" my={1}>
          Modified time: {formatDateTime(campaignDetails?.modifiedTime)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignDetails;
