import React, { useEffect, useState } from 'react';
import { Box, Button, Slide, Stack, Typography } from '@mui/material';
import {
  DeleteCampaign,
  FetchAllCampaign,
  FetchAllSegment,
  FetchAllTemplate,
  UpdateCampaign,
} from 'Api/Api';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { CampaignTableColumns } from 'constants/appConstant';
import CreateCampaignModal from './CreateCampaignModal';
import EditCampaignModal from './EditCampaignModal';
import toast from 'react-hot-toast';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import NoRecord from 'components/NoRecord';
import EnlargeCampaign from './EnlargeCampaign';
import CampaignDetails from './CampaignDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Campaign = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [searchTerms, setSearchTerms] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [enlargeOpen, setEnlargeOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [createRealtimeModalOpen, setCreateRealtimeModalOpen] = useState(false);
  const [campaignDataRealtime, setCampaignDataRealtime] = useState([]);
  const handleCreateModal = () => setCreateModalOpen(true);

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const handleEnlargeCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setEnlargeOpen(true);
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
    setEnlargeOpen(false);
  };

  const fetch = async () => {
    try {
      setInitialLoading(true);
      const response = await FetchAllCampaign();
      if (response?.data?.status === true) {
        setCampaignData(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await FetchAllTemplate();
      if (response?.data?.status === true) {
        setTemplateDetails(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchSegments = async () => {
    try {
      const response = await FetchAllSegment();
      if (response?.data?.status === true) {
        setSegmentDetails(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const updateStatus = async (item) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    try {
      const response = await UpdateCampaign(item?.id, {
        id: item.id,
        status: item.status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        fetch();
      }
    } catch (error) {
      toast.error('Failed to update status.');
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  };

  const deleteCampaign = async (recordId) => {
    try {
      const response = await DeleteCampaign(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchCampaigns = () => {
    const storedCampaigns = localStorage.getItem('campaigns');
    if (storedCampaigns) {
      setCampaignDataRealtime(JSON.parse(storedCampaigns));
    } else {
      console.log('No campaigns found in local storage.');
    }
    setInitialLoading(false);
  };

  const handleCampaignSubmit = (newCampaign) => {
    const timestamp = new Date().toISOString();
    const campaignWithTimestamps = {
      ...newCampaign,
      createdTime: timestamp,
      updatedTime: timestamp,
      frequency: '',
    };
    setCampaignDataRealtime((prevCampaigns) => {
      const updatedCampaigns = [...prevCampaigns, campaignWithTimestamps];
      localStorage.setItem('campaigns', JSON.stringify(updatedCampaigns));
      return updatedCampaigns;
    });
    toast.success('Campaign created successfully!');
  };

  useEffect(() => {
    fetch();
    fetchTemplates();
    fetchSegments();
  }, []);

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Campaigns
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={handleCreateModal}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={campaignData}
            rowData={CampaignTableColumns}
            handleDeleteModel={handleDeleteModel}
            handleEditModel={handleEditModel}
            handleEnlargeModal={handleEnlargeCampaign}
            updateStatus={updateStatus}
            initialLoading={initialLoading}
            loading={loading}
            icons={['edit', 'delete', 'detail', 'enlarge']}
            sortingIndex={[
              'status',
              'campaignName',
              'Type',
              'createdTime',
              'modifiedTime',
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Campaign"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>
        <CreateCampaignModal
          TransitionComponent={Transition}
          open={createModalOpen}
          onClose={onClose}
          templates={templateDetails}
          segments={segmentDetails}
          refresh={fetch}
          FetchTemplate={fetchTemplates}
          FetchSegment={fetchSegments}
          openRealTime={createRealtimeModalOpen}
          refreshRealTime={handleCampaignSubmit}
        />
        <EditCampaignModal
          TransitionComponent={Transition}
          open={editModalOpen}
          onClose={onClose}
          itemId={editId}
          isDetail={isDetail}
          refresh={fetch}
          templates={templateDetails}
          segments={segmentDetails}
          FetchTemplate={fetchTemplates}
          FetchSegment={fetchSegments}
        />

        <DeleteModal
          open={deleteModalOpen}
          close={onClose}
          placeholder="campaign"
          deleteFunction={() => deleteCampaign(deleteId)}
        />
        <EnlargeCampaign
          open={enlargeOpen}
          onClose={() => setEnlargeOpen(false)}
          itemId={selectedCampaign}
          templates={templateDetails}
          segments={segmentDetails}
          FetchTemplate={fetchTemplates}
          FetchSegment={fetchSegments}
        />
      </Box>
    </MainDashboard>
  );
};

export default Campaign;
