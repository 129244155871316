import React from 'react';
import { Button } from '@mui/material';

const Toolbox = ({ onAddElement }) => {
    const elementTypes = ['Text Input', 'Multiple Choice', 'Checkbox', 'Dropdown'];

    return (
        <div className="bg-gray-100 p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Toolbox</h2>
            {elementTypes.map((type) => (
                <Button
                    key={type}
                    onClick={() => onAddElement(type)}
                    className="w-full mb-2"
                >
                    Add {type}
                </Button>
            ))}
        </div>
    );
};

export default Toolbox;
