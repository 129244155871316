import {
  accountIcon,
  helpIcon,
  joinCommunityIcon,
  logoutIcon,
  reviewIcon,
  settingsIcon,
  termsAndConditionIcon,
} from 'assets/Icons/ProfileImages';
import { protectedRoutes, unprotectedRoutes } from './appRoutes';
import * as appImages from './appImages';

import {
  analyticsIconActive,
  analyticsIconInactive,
  automationIconActive,
  automationIconInactive,
  campaignIconActive,
  campaignIconInactive,
  connectionIconActive,
  connectionIconInactive,
  dashboardIconActive,
  dashboardIconInactive,
  feedsIconActive,
  feedsIconInactive,
  realTimeIconActive,
  realTimeIconInactive,
  segmentIconActive,
  segmentIconInactive,
  sequenceIconActive,
  sequenceIconInactive,
  subscriberIconActive,
  subscriberIconInactive,
  templateIconActive,
  templateIconInactive,
  webPushIconActive,
  webPushIconInactive,
} from 'assets/Icons/SideBarImages';

export const ProfileMenuItems = [
  {
    name: 'Profile',
    icon: accountIcon,
    link: protectedRoutes?.profile,
  },
  // {
  //   name: 'Settings',
  //   icon: settingsIcon,
  //   link: '',
  // },
  {
    name: 'Help',
    icon: helpIcon,
    link: 'mailto:support@letsnotify.co.in',
  },
  {
    name: 'Leave a Review',
    icon: reviewIcon,
    link: 'https://forms.gle/bRSmDqgm3iD61HMB8',
  },

  {
    name: 'Join Community',
    icon: joinCommunityIcon,
    link: 'https://discord.gg/ywfExnAU',
  },
  {
    name: 'Terms And Conditions',
    icon: termsAndConditionIcon,
    link: unprotectedRoutes.termsAndCondition,
  },
  {
    name: 'Log Out',
    icon: logoutIcon,
    link: protectedRoutes.login,
  },
];

export const SideMenuItems = [
  {
    name: 'Dashboard',
    icon: {
      active: dashboardIconActive,
      inactive: dashboardIconInactive,
    },
    link: protectedRoutes.dashboard,
  },
  {
    name: 'Web Push',
    icon: {
      active: webPushIconActive,
      inactive: webPushIconInactive,
    },
    child: [
      // {
      //   name: 'Campaigns',
      //   icon: {
      //     active: campaignIconActive,
      //     inactive: campaignIconInactive,
      //   },
      //   link: protectedRoutes.webCampaigns,
      // },
      {
        name: 'Templates',
        icon: {
          active: templateIconActive,
          inactive: templateIconInactive,
        },
        link: protectedRoutes.templates,
      },
      {
        name: 'Sequence',
        icon: {
          active: sequenceIconActive,
          inactive: sequenceIconInactive,
        },
        link: protectedRoutes.sequence,
      },

      {
        name: 'Automation',
        icon: {
          active: automationIconActive,
          inactive: automationIconInactive,
        },
        link: '',
        comingSoon: true,
      },
    ],
  },
  {
    name: 'Real-time',
    icon: {
      active: realTimeIconActive,
      inactive: realTimeIconInactive,
    },
    child: [
      // {
      //   name: 'Campaigns',
      //   icon: {
      //     active: campaignIconActive,
      //     inactive: campaignIconInactive,
      //   },
      //   link: protectedRoutes.realtimeCampaigns,
      // },
      {
        name: 'Analytics',
        icon: {
          active: analyticsIconActive,
          inactive: analyticsIconInactive,
        },
        link: protectedRoutes.analytics,
      },
      {
        name: 'Feeds',
        icon: {
          active: feedsIconActive,
          inactive: feedsIconInactive,
        },
        link: protectedRoutes.feeds,
      },
    ],
  },
  {
    name: 'Segments',
    icon: {
      active: segmentIconActive,
      inactive: segmentIconInactive,
    },
    link: protectedRoutes.segments,
  },
  {
    name: 'Campaigns',
    icon: {
      active: campaignIconActive,
      inactive: campaignIconInactive,
    },
    link: protectedRoutes.webCampaigns,
  },
  {
    name: 'Subscribers',
    icon: {
      active: subscriberIconActive,
      inactive: subscriberIconInactive,
    },
    link: protectedRoutes.subscribers,
  },
  {
    name: 'Connections',
    icon: {
      active: connectionIconActive,
      inactive: connectionIconInactive,
    },
    link: protectedRoutes.connections,
  },
  {
    name: 'Web Builder',
    icon: {
      active: campaignIconActive,
      inactive: campaignIconInactive,
    },
    link: protectedRoutes.webBuilder
  },
  {
    name: 'Survey',
    icon: {
      active: campaignIconActive,
      inactive: campaignIconInactive,
    },
    link: protectedRoutes.survey
  }
];

export const CampaignTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '1%',
    textAlign: 'left',
    hoverable: false,
  },
  {
    id: 'campaignName',
    label: 'Campaign Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'Type',
    label: 'Type',
    align: 'center',
    width: '9%',
    textAlign: 'left',
    hoverable: false,
  },
  {
    id: 'frequency',
    label: 'Frequency',
    align: 'center',
    width: '5%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '12%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '12%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const FeedTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '1%',
    textAlign: 'left',
    hoverable: false,
  },
  {
    id: 'feedName',
    label: 'Feed Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'feedurl',
    label: 'Url',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'frequency',
    label: 'Frequency',
    align: 'center',
    width: '5%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '12%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'Source',
    label: 'Source',
    align: 'center',
    width: '12%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const TemplateTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    width: '1%',
    textAlign: 'left',
    hoverable: false,
  },
  {
    id: 'templateName',
    label: 'Template Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'title',
    label: 'Title',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'pageURL',
    label: 'Redirecting URL',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const ConnectionTableColumns = [
  {
    id: 'connectionName',
    label: 'Connection Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'connectionUrl',
    label: 'Connection URL',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '15%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const SegmentTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '1%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'segmentName',
    label: 'Segment Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'subscriberCount',
    label: 'Count',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const SubscribersTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    width: '1%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'subscriptionId',
    label: 'Subscribers ID',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const WebBuilderTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '1%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'webBuilderName',
    label: 'Web Builder Name',
    align: 'center',
    width: '15%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'websiteUrl',
    label: 'Website URL',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: true,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
];

export const SurveyTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '10%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'name',
    label: 'Survey Name',
    align: 'center',
    width: '30%',
    textAlign: 'left',
    hoverable: true,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '20%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '20%',
    textAlign: 'center',
    hoverable: false,
  },
  {
    id: 'surveyId',
    label: 'Survey ID',
    align: 'center',
    width: '20%',
    textAlign: 'center',
    hoverable: true,
  },
];

export const SegmentFieldsArray = [
  { name: 'Mobile', value: 'mobile' },
  { name: 'Platform', value: 'platform' },
  { name: 'Browser', value: 'browser' },
  { name: 'Visitor Url', value: 'visited_url' },
  { name: 'Created Time', value: 'createdTime' },
];

export const SegmentConditionArray = [
  { name: 'Equal', value: 'Equal' },
  { name: 'Not Equal', value: 'Not Equal' },
];


export const ConditionsArray = [
  { name: 'Properties about someone', value: 'Properties_about_someone' },
  { name: 'What someone has done(or not done)', value: 'What_someone_has_done' },
];

export const PersonHasArray = [
  { name: 'Active on Site', value: 'Active_on_Site' },

];

export const ActionArray = [
  { name: 'At least once', value: 'At_least_once' },
  { name: 'Zero times', value: 'Zero_times' },
  { name: 'Equals', value: 'Equals' },
  { name: 'Does not equal', value: 'Does_not_equal' },
  { name: 'Is at least', value: 'Is_at_least' },
  { name: 'Is greater than', value: 'Is_greater_than' },
  { name: 'Is less than', value: 'Is_less_than' },
  { name: 'Is at most', value: 'Is_at_most' }
];


export const TimePeriodArray = [
  { name: 'In the last', value: 'In_the_last' },
  { name: 'After', value: 'After' },
  { name: 'Before', value: 'Before' },
  { name: 'Between', value: 'Between' },
  { name: 'Between dates', value: 'Between_dates' },
  { name: 'At least', value: 'At_least' },
  { name: 'Over all time', value: 'Over_all_time' }
];

export const CalenderArray = [
  { name: 'Days', value: 'Days' },
  { name: 'Weeks', value: 'Weeks' },
  { name: 'Hours', value: 'Hours' }
];

export const ConditionDrawerArray = [
  { name: 'Minutes', value: 'Minutes' },
  { name: 'Hours', value: 'Hours' },
  { name: 'Days', value: 'Days' },
];

export const SequenceTableColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    width: '1%',
    textAlign: 'left',
  },
  {
    id: 'sequenceName',
    label: 'Sequence Name',
    align: 'center',
    width: '20%',
    textAlign: 'center',
    hoverable: true,
  },
  {
    id: 'createdTime',
    label: 'Created Time',
    align: 'center',
    width: '12%',
    textAlign: 'center',
  },
  {
    id: 'modifiedTime',
    label: 'Updated Time',
    align: 'center',
    width: '12%',
    textAlign: 'center',
  },
];

export const commonIcons = {
  campaignIcon: appImages.campaignIcon,
  segmentIcon: appImages.segmentIcon,
  copyIcon: appImages.copyIcon,
  conditionIcon: appImages.conditionIcon,
  editIcon: appImages.editIcon,
  listViewEdit: appImages.listViewEdit,
  enlargeIcon: appImages.enlargeIcon,
  trashIcon: appImages.trashIcon,
  detailsIcon: appImages.detailsIcon,
};

export const listViewIcons = [
  {
    icon: appImages.listViewEdit,
    title: 'Edit',
  },
  {
    icon: appImages.enlargeIcon,
    title: 'Enlarge',
  },
  {
    icon: appImages.trashIcon,
    title: 'Delete',
  },
];

export const svgBorder = `
<svg xmlns="http://www.w3.org/2000/svg" width="2" height="30" viewBox="0 0 2 39" fill="none">
  <path d="M1 1L1 38" stroke="#B9B9B9" stroke-opacity="0.9" stroke-linecap="round" stroke-width="2"/>
</svg>
`;


export const CampaignScheuduler = [
  { name: 'Now', value: 'now' },
  { name: 'On specific date', value: 'on_specific_date' },
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
];

export const SequenceScheuduler = [
  { name: 'On specific date', value: 'on_specific_date' },
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
];
