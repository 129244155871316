import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trash2 } from 'lucide-react';
import { Input } from '@mui/material';
import { Label } from '@mui/icons-material';
import { Button } from '@mui/material';

const FormElementComponent = ({ element, index, updateElement, moveElement, deleteElement }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'form-element',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveElement(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'form-element',
        item: () => {
            return { id: element.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;

    return (
        <div ref={ref} style={{ opacity }} className="mb-4 p-4 bg-gray-50 rounded shadow">
            <div className="flex justify-between items-center mb-2">
                <Label htmlFor={`element-${element.id}`}>{element.type}</Label>
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => deleteElement(element.id)}
                    className="h-8 w-8 p-0"
                >
                    <Trash2 className="h-4 w-4" />
                </Button>
            </div>
            <Input
                id={`element-${element.id}`}
                value={element.label}
                onChange={(e) => updateElement(element.id, { label: e.target.value })}
                className="mt-1"
            />
        </div>
    );
};

export default FormElementComponent;
